<template>
<div>
  <v-footer app color="primary" dark>
        <div color="secondary">&copy; CFDM-v2.0 {{ new Date().getFullYear() }}</div>
  </v-footer>
</div>
</template>
<script>
export default {
  name: 'landing-footer',
  props: {

  }
}
</script>
